import { appPaths, getPathUrl } from 'utils/path'

export function insertOtherLinksOnAbout(relatedPages: { title: string; url: string }[], isDoctorPage: boolean) {
  const appPath = appPaths.history
  const historyUrl = getPathUrl(appPath.path, isDoctorPage)
  relatedPages.push({
    title: appPath.name,
    url: historyUrl
  })
}
